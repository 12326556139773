<template>

<div class="content" :class="{'is-white': white, 'is-disabled': disabled, 'is-grow': grow, 'is-center': center, 'is-blue': blue, 'is-inner': inner}">

	<slot></slot>

	<app-icon icon="loading" v-if="loading" class="loading" />

</div>

</template>

<script>

export default {

	props: ['white', 'blue', 'inner', 'center', 'loading', 'disabled', 'grow']

}

</script>

<style>

.content {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.content.is-disabled {
	opacity: 0.25;
	pointer-events: none;
}

.content.is-center {
	justify-content: center;
}

.content.is-grow {
	flex-grow: 1;
}

.content.is-blue {
	background-color: #1e3054;
}

.content.is-white {
	background-color: #fff;
}

.content.is-inner {
	max-width: 1000px;
}

.loading {
	position: absolute;
	left: 50%;
	top: 50%;
	font-size: 48px;
	transform: translate(-50%, -50%);
	color: #ffbe7a;
}

</style>
